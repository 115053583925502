<template>
  <div>
    <v-app-bar elevation="4">
      <v-img :src="require('@/assets/images/logos/logo-small.png')" max-width="130px" alt="logo" contain
        class="me-3"></v-img>
    </v-app-bar>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card class="auth-card">
          <!-- logo -->
          <v-card-title style="width: 100%; display: block; text-align: center" class="align-center justify-center">
            <router-link style="" to="/" class="align-center">
              <h2 class="text-2xl font-weight-semibold">SIDIKBANG TEGAL</h2>
              <h5>
                SISTEM DIGITALISASI <br />Pendidikan, Pelatihan dan Pengembangan<br />RSUD dr SOESELO KABUPATEN TEGAL
              </h5>
            </router-link>
          </v-card-title>

          <!-- login form -->
          <v-card-text>
            <v-form>
              <v-text-field v-model="email" outlined label="Email" dense placeholder="xxx / xxx@xxx.com" hide-details
                class="mb-2"></v-text-field>
              <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'" dense
                label="Password" placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details
                @click:append="isPasswordVisible = !isPasswordVisible" class="mb-2"></v-text-field>
              <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess"
                @error="handleError">
              </VueRecaptcha>
              <v-btn block color="primary" class="mt-2" @click="login()" :loading="loading"> Login </v-btn>
            </v-form>
          </v-card-text>
          <v-card-text class="align-center justify-center mt-2 text-center">
            <span> Belum verifikasi email? </span>
            <router-link :to="{ name: 'Verifikasi' }"> Verifikasi </router-link>
            <br />
            <span> Belum punya akun? </span>
            <router-link :to="{ name: 'register' }"> Register </router-link>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    const siteKey = process.env.VUE_APP_CAPTCHA_KEY

    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')

    return {
      siteKey,
      isPasswordVisible,
      email,
      password,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data: () => ({
    loading: false,
    recaptcha: '',
  }),
  created() {
    if (localStorage.getItem("email") != null) {
      this.email = localStorage.getItem("email")
      localStorage.clear();
    }
  },
  methods: {
    handleError() { },
    handleSuccess(response) {
      this.recaptcha = response
    },
    login() {
      this.loading = true
      const fmData = new FormData()
      fmData.append('username', this.email)
      fmData.append('password', this.password)
      fmData.append('recaptcha', this.recaptcha)
      axios
        .post(`${apiRoot}/Authentication/login`, fmData)
        .then(response => {
          if (response.data.code === 200) {
            const data = {
              token: response.data.token,
            }
            this.$store.dispatch('login', data)
            this.$router.push('/')
          } else if (response.data.code === 202) {
            alert(response.data.message)
            this.$router.push({ name: 'Verifikasi' })
          } else {
            alert(response.data.message)
            if (response.data.message[0] == "timeout-or-duplicate") {
              localStorage.setItem("email", this.email);
              location.reload();
            }
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
